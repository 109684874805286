import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer } from "../../../components/FlexContainer";
import { REACT_PRODUCTS_URL } from "../../../constant";
import { fetchItems } from "../../../redux/slices/actionsShopSlice";
import { ProductInterface } from "../../../redux/slices/interface/resp/Product.interface";
import { UserInterface } from "../../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../../redux/slices/mySlice";
import { selectItems } from "../../../redux/slices/shopSlice";
import { ItemBlock } from "./ItemBlock/ItemBlock";

export const MainProductsPage = () => {
  const dispatch = useDispatch();

  const user: UserInterface | null = useSelector(selectUser);
  const products: Array<ProductInterface> = useSelector(selectItems);

  useEffect(() => {
    if (user === null) {
      return;
    }

    dispatch(fetchItems(REACT_PRODUCTS_URL) as any);
  }, [dispatch, user]);

  if (user === null) {
    return <></>;
  }

  return (
    <FlexContainer $direction="column" $gap="25px">
      {products.map((item) => (
        <ItemBlock item={item} key={item.id} />
      ))}
    </FlexContainer>
  );
};
