import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Button } from "../../components/Button.styled";

import { StyledInputInline } from "../../components/Inline.styled";
import { TextAriaStyled } from "../../components/TextAria.styled";
import {
  REACT_CREATE_ITEM_URL,
  REACT_CREATE_PROMO_URL,
  REACT_DELETE_ITEM_URL,
  REACT_UPDATE_ITEM_URL,
} from "../../constant";
import {
  createPromocodes,
  deleteItem,
  sendItem,
} from "../../redux/slices/actionsAdminSlice";
import { CreatePromoParamsInterface } from "../../redux/slices/interface/req/CreatePromoParams.interface";
import { SendItemParamsInterface } from "../../redux/slices/interface/req/SendItemParams.interface";
import { ProductInterface } from "../../redux/slices/interface/resp/Product.interface";
import { UserInterface } from "../../redux/slices/interface/resp/User.interface";

export const SpecialForm = ({
  user,
  products,
  isPromoCode,
}: {
  user: UserInterface;
  products: Array<ProductInterface>;
  isPromoCode: boolean;
}) => {
  const dispatch = useDispatch();

  const [priceProduct, setPriceProduct] = useState<number>(0);
  const [nameProduct, setNameProduct] = useState<string>("");
  const [descriptionProduct, setDescriptionProduct] = useState<string>("");
  const [typeProduct, setTypeProduct] = useState<string>("");
  const [MyAnswersProduct, setMyAnswersProduct] = useState<number>(0);
  const [GPTProduct, setGPTProduct] = useState<number>(0);
  const [days, setDays] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [itemId, setItemId] = useState<string>("");
  const [item, setItem] = useState<ProductInterface | null>(null);

  useEffect(() => {
    if (!isPromoCode) {
      const item: ProductInterface | undefined = products.find(
        (item) => item.id === itemId
      );
      if (itemId === "" || item === undefined) {
        setItem(null);
        setPriceProduct(0);
        setNameProduct("");
        setDescriptionProduct("");
        setTypeProduct("");
        setMyAnswersProduct(0);
        setGPTProduct(0);
        setDays(0);
        return;
      } else {
        setItem(item);
        setPriceProduct(item.price);
        setNameProduct(item.name);
        setDescriptionProduct(item.description);
        setTypeProduct(item.type);
        setMyAnswersProduct(item.MyAnswers ?? 0);
        setGPTProduct(item.GPT ?? 0);
        setDays(item.days ?? 0);
      }
    }
  }, [itemId, item, products, isPromoCode]);

  function sendProduct(isPromoCode: boolean) {
    if (!isPromoCode) {
      if (
        priceProduct === 0 ||
        nameProduct === "" ||
        descriptionProduct === "" ||
        typeProduct === "" ||
        user === null
      ) {
        return;
      }
      const data: SendItemParamsInterface = {
        url: item === null ? REACT_CREATE_ITEM_URL : REACT_UPDATE_ITEM_URL,
        userIdTg: user.idTg,
        name: nameProduct,
        description: descriptionProduct,
        type: typeProduct,
        MyAnswers: MyAnswersProduct,
        GPT: GPTProduct,
        id: itemId,
        price: priceProduct,
        days: days,
      };

      dispatch(sendItem(data) as any);
    } else {
      if (
        nameProduct === "" ||
        descriptionProduct === "" ||
        typeProduct === "" ||
        user === null
      ) {
        return;
      }
      const data: CreatePromoParamsInterface = {
        url: REACT_CREATE_PROMO_URL,
        userIdTg: user.idTg,
        name: nameProduct,
        description: descriptionProduct,
        type: typeProduct,
        MyAnswers: MyAnswersProduct,
        GPT: GPTProduct,
        id: itemId,
        price: priceProduct,
        days: days,
        quantity: quantity,
      };

      dispatch(createPromocodes(data) as any);
    }
  }

  function deleteProduct(isPromoCode: boolean) {
    if (!isPromoCode) {
      if (user === null || itemId === "") {
        return;
      }
      const data = {
        url: REACT_DELETE_ITEM_URL,
        userIdTg: user.idTg,
        id: itemId,
      };
      dispatch(deleteItem(data) as any);
      // Логика удаления данных на сервере
    }
  }

  function checkerVisibleOfButton(item: ProductInterface | null): boolean {
    if (
      priceProduct === 0 ||
      nameProduct === "" ||
      descriptionProduct === "" ||
      typeProduct === "" ||
      user === null
    ) {
      return false;
    } else {
      if (item === null) {
        return true;
      } else
        return (
          item.price !== priceProduct ||
          item.name !== nameProduct ||
          item.description !== descriptionProduct ||
          item.type !== typeProduct ||
          (item.MyAnswers ?? 0) !== MyAnswersProduct ||
          (item.GPT ?? 0) !== GPTProduct ||
          (item.days ?? 0) !== days
        );
    }
  }

  return (
    <>
      <StyledInputInline type="label">
        <label htmlFor={`products`}>
          <b>Товары</b>
        </label>
        <select
          id={`products`}
          value={itemId}
          onChange={(e) => setItemId(e.target.value)}
        >
          <option value={""}>Не выбрано</option>
          {products.map((item) => (
            <option key={item.id} value={item.id}>
              {`${item.type}-${item.price}`}
            </option>
          ))}
        </select>
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`price`}>
          <b>Цена</b>
        </label>
        <input
          type="number"
          min={0}
          id={`price`}
          value={priceProduct}
          onChange={(e) => setPriceProduct(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`name`}>
          <b>Название</b>
        </label>
        <input
          type="text"
          id={`name`}
          value={nameProduct}
          onChange={(e) => setNameProduct(e.target.value)}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`description`}>
          <b>Описание</b>
        </label>
        <TextAriaStyled
          style={{ boxSizing: "border-box" }}
          cacheMeasurements
          id={`description`}
          value={descriptionProduct}
          onChange={(e) => setDescriptionProduct(e.target.value)}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`type`}>
          <b>Тип</b>
        </label>
        <select
          id={`type`}
          value={typeProduct}
          onChange={(e) => setTypeProduct(e.target.value)}
        >
          <option value={""}>Не выбрано</option>
          <option value={"GPT"}>GPT</option>
          <option value={"MYANSWERS"}>MYANSWERS</option>
          <option value={"MYANSWERS_AND_GPT"}>MYANSWERS_AND_GPT</option>
          <option value={"SUBSCRIPTION"}>SUBSCRIPTION</option>
        </select>
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`MyAnswers`}>
          <b>MyAnswers</b>
        </label>
        <input
          type="number"
          min={0}
          id={`MyAnswers`}
          value={MyAnswersProduct}
          onChange={(e) => setMyAnswersProduct(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`GPT`}>
          <b>GPT</b>
        </label>
        <input
          type="number"
          min={0}
          id={`GPT`}
          value={GPTProduct}
          onChange={(e) => setGPTProduct(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`days`}>
          <b>Дни</b>
        </label>
        <input
          type="number"
          min={0}
          id={`days`}
          value={days}
          onChange={(e) => setDays(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      {isPromoCode && (
        <StyledInputInline type="label">
          <label htmlFor={`quantity`}>
            <b>Количество</b>
          </label>
          <input
            type="number"
            min={0}
            id={`quantity`}
            value={quantity}
            onChange={(e) => setQuantity(+e.target.value)}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
        </StyledInputInline>
      )}

      <Button
        onClick={() => sendProduct(isPromoCode)}
        disabled={!checkerVisibleOfButton(item)}
      >
        Отправить
      </Button>
      {!isPromoCode && (
        <Button
          onClick={() => deleteProduct(isPromoCode)}
          disabled={itemId === ""}
        >
          Удалить
        </Button>
      )}
    </>
  );
};
