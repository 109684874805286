import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Block } from "../../../../components/Block.styled";
import { Button, LittleButton } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { myTheme } from "../../../../components/Theme.styled";
import {
  httpStatusCodes,
  REACT_CHANGE_EX_WORDS_URL,
} from "../../../../constant";
import { updateExWords } from "../../../../redux/slices/actionsMySlice";
import { setError } from "../../../../redux/slices/errorSlice";
import {
  selectMySliceIsLoading,
  selectUser,
} from "../../../../redux/slices/mySlice";

const _ = require("lodash");

const ExWordsBlock = () => {
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectMySliceIsLoading);
  const dispatch = useDispatch();
  const [newWords, setNewWords] = useState<string[]>(
    user ? [...user.words] : []
  );
  const [word, setWord] = useState<string>("");

  if (!user) {
    return <div>Ошибка загрузки данных</div>;
  }

  const handleWordChange = (word: string) => {
    const updatedWords = newWords.includes(word)
      ? newWords.filter((item) => item !== word)
      : [...newWords, word];
    setNewWords(updatedWords);
  };

  const handleAddWord = () => {
    const trimmedWord = word.trim();
    if (trimmedWord && !newWords.includes(trimmedWord)) {
      if (newWords.length < 30) {
        setNewWords([...newWords, trimmedWord]);
        setWord("");
      } else {
        dispatch(
          setError({
            message: "Достигнут лимит в 30 слов.",
            status: httpStatusCodes.BAD_REQUEST,
          })
        );
      }
    }
  };

  const saveWords = () => {
    if (user) {
      if (newWords.length > 30) {
        dispatch(
          setError({
            message: "Невозможно сохранить. Достигнут лимит в 30 слов.",
            status: httpStatusCodes.BAD_REQUEST,
          })
        );
      } else {
        dispatch(
          updateExWords({
            words: newWords,
            userIdTg: user.idTg,
            url: REACT_CHANGE_EX_WORDS_URL,
          }) as any
        );
      }
    }
  };

  const isChanged = !_.isEqual(newWords, user.words);

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <h2>
          <b>Слова исключения</b>{" "}
        </h2>
        <StyledUl>
          {user.words
            .concat(newWords.filter((word) => !user.words.includes(word)))
            .map((word) => (
              <li key={word}>
                {newWords.includes(word) ? <>{word}</> : <s>{word}</s>}
                <LittleButton
                  $bgc="transparent"
                  color={`${myTheme.colors.light.primary}`}
                  onClick={() => handleWordChange(word)}
                >
                  {newWords.includes(word) ? "X" : "V"}
                </LittleButton>
              </li>
            ))}
        </StyledUl>
        <StyledInputInline type="button">
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value.toLowerCase().trim())}
            placeholder="Введите слово"
          />
          <Button onClick={handleAddWord} disabled={!word.trim()}>
            Добавить
          </Button>
        </StyledInputInline>
        <Button
          $sizeButton="little"
          disabled={isLoading || !isChanged}
          onClick={saveWords}
        >
          Сохранить
        </Button>
      </FlexContainer>
    </Block>
  );
};

export default ExWordsBlock;

const StyledUl = styled.ul`
  max-width: 100%;
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;
