import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Block } from "../../components/Block.styled";
import { Button } from "../../components/Button.styled";

import { FlexContainer } from "../../components/FlexContainer";
import { httpStatusCodes, REACT_PRODUCTS_URL } from "../../constant";
import { fetchItems } from "../../redux/slices/actionsShopSlice";
import { selectAdminCodes } from "../../redux/slices/adminSlice";
import { setError } from "../../redux/slices/errorSlice";
import { ProductInterface } from "../../redux/slices/interface/resp/Product.interface";
import { UserInterface } from "../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../redux/slices/mySlice";
import { selectItems } from "../../redux/slices/shopSlice";
import { GetCourseForm } from "./GetCourseForm";
import { SpecialForm } from "./SpecialForm";
export const AdminPage = () => {
  const dispatch = useDispatch();
  const user: UserInterface | null = useSelector(selectUser);
  const products: Array<ProductInterface> = useSelector(selectItems);
  const [openAppProduct, setOpenAppProduct] = useState(false);
  const [openAppPromoCode, setOpenAppPromoCode] = useState(false);
  const codes = useSelector(selectAdminCodes);

  useEffect(() => {
    if (codes) {
      dispatch(
        setError({ message: "ПромокодЫ созданы", status: httpStatusCodes.OK })
      );
    }
  });

  useEffect(() => {
    if (user === null) {
      return;
    }
    if (openAppProduct) {
      dispatch(fetchItems(REACT_PRODUCTS_URL) as any);
    }
  }, [dispatch, user, openAppProduct]);

  function switchAddProduct() {
    setOpenAppProduct(!openAppProduct);
  }

  function switchAddPromoCode() {
    setOpenAppPromoCode(!openAppPromoCode);
  }

  if (user === null || user.role !== "admin") {
    return <></>;
  }

  return (
    <FlexContainer $direction="column" $gap="25px">
      <Block>
        <FlexContainer $direction="column" $gap="15px">
          <b>Добавить товар</b>
          <Button onClick={switchAddProduct}>Развернуть</Button>
          {openAppProduct && (
            <SpecialForm user={user} products={products} isPromoCode={false} />
          )}
        </FlexContainer>
      </Block>
      <Block>
        <FlexContainer $direction="column" $gap="15px">
          <b>Промокоды</b>
          <Button onClick={switchAddPromoCode}>Развернуть</Button>
          {openAppPromoCode && (
            <SpecialForm user={user} products={[]} isPromoCode={true} />
          )}
        </FlexContainer>
      </Block>
      <Block>
        <FlexContainer $direction="column" $gap="15px">
          <b>Промокод на мыло</b>
          <GetCourseForm user={user} />
        </FlexContainer>
      </Block>
    </FlexContainer>
  );
};
