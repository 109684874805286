import styled, { css } from "styled-components";
import { myTheme } from "./Theme.styled";

type StyledInputInlineType = {
  type?: "button" | "label" | "little";
  width?: string;
};

export const StyledInputInline = styled.div<StyledInputInlineType>`
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
  justify-content: stretch;
  gap: 5px;
  width: 100%;

  input,
  select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: ${myTheme.other.borderRadius};
    border: 1px solid ${myTheme.colors.light.primary};
    background: ${myTheme.colors.dark.secondary};
    font-size: medium;
    color: ${myTheme.colors.light.primary};
  }

  ${(props) =>
    props.type === "button" &&
    css<StyledInputInlineType>`
      height: auto;
      input {
        max-width: 70%;
      }
      button {
        margin: 0;
        max-width: 50%;
      }
    `}

  ${(props) =>
    props.type === "label" &&
    css<StyledInputInlineType>`
      height: fit-content;
      label {
        font-size: 1em;
        color: ${myTheme.colors.light.primary};
        width: 30%;
        max-width: 30%;
      }
      input {
        width: 70%;
        max-width: 70%;
      }
    `}

  ${(props) =>
    props.type === "little" &&
    css<StyledInputInlineType>`
      height: auto;
      border: 1px solid ${myTheme.colors.light.primary};
      padding: 0px 5px 0px 5px;
      align-items: center;
      justify-content: space-between;
      border-radius: ${myTheme.other.borderRadius};
      background-color: ${myTheme.colors.dark.secondary};
      width: ${(props) => props.width ?? "fit-content"};
      max-width: 100%;
      height: 30px;
      gap: 2px;

      label {
        font-size: 1em;
        color: ${myTheme.colors.light.primary};
        /* max-width: 50%; */
      }

      select {
        max-width: 60%;
        font-size: small;
        color: ${myTheme.colors.dark.secondary};
        background-color: ${myTheme.colors.light.primary};
      }

      input {
        max-width: 100%;
        width: fit-content;
      }
    `}
`;
