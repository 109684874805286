import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { fetchReviews, replyReview } from "./actionsReviewsSlice";
import { ViewReviewModeInterface } from "./interface/resp/Review.interface";

interface ReviewsState {
  replied: Array<ViewReviewModeInterface>;
  noReplied: Array<ViewReviewModeInterface>;
  isLoading: boolean;
  isReplying: boolean;
  timeReq: number | null;
  skipForCircle: number | null;
}

const initialState: ReviewsState = {
  replied: [],
  noReplied: [],
  isReplying: true,
  isLoading: false,
  timeReq: null,
  skipForCircle: null,
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setIsReplying: (state, action: { payload: boolean }) => {
      state.isReplying = action.payload;
    },
    setTimeReq: (state, action: { payload: number | null }) => {
      state.timeReq = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchReviews.fulfilled,
        (
          state,
          action: {
            payload: {
              data: {
                reviews: Array<ViewReviewModeInterface>;
                skipForCircle: number;
              };
              isAnswered: boolean;
            };
          }
        ) => {
          if (action.payload.data.reviews.length !== 0) {
            if (action.payload.isAnswered) {
              if (action.payload.data.skipForCircle === 0) {
                state.replied = action.payload.data.reviews;
              } else {
                state.replied = [
                  ...state.replied.map((existingReview) => {
                    const updatedReview = action.payload.data.reviews.find(
                      (newReview) => newReview.id === existingReview.id
                    );
                    return updatedReview ?? existingReview;
                  }),

                  ...action.payload.data.reviews.filter(
                    (newReview) =>
                      !state.replied.some(
                        (existingReview) => existingReview.id === newReview.id
                      )
                  ),
                ];
              }
              state.skipForCircle = action.payload.data.skipForCircle;
            } else {
              state.noReplied = action.payload.data.reviews;
            }
          }
          state.isLoading = false;
        }
      )
      .addCase(fetchReviews.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      // .addCase(replyReview.pending, (state) => {})
      .addCase(
        replyReview.fulfilled,
        (
          state,
          action: {
            payload: ViewReviewModeInterface;
          }
        ) => {
          if (action.payload.answer !== null) {
            const isExist: ViewReviewModeInterface | undefined =
              state.replied.find((review) => review.id === action.payload.id);
            if (isExist === undefined) {
              state.replied.unshift(action.payload);
            } else {
              state.replied = state.replied.map((review) => {
                if (review.id === action.payload.id) {
                  if (review.answer!.text === action.payload.answer!.text) {
                    const updatedInfoReview = {
                      ...review,
                    };
                    updatedInfoReview.answer!.text =
                      updatedInfoReview.answer!.text += `\n\n(Уведомление:\nWB принял эти обновления, они находятся на рассмотрении, но пока не отображаются на сайте и у нас, потребуется время, не переживайте.)`;
                    updatedInfoReview.answer!.editable = false;
                    return updatedInfoReview;
                  } else {
                    return action.payload;
                  }
                }
                return review;
              });
            }
          }
        }
      );
    // .addCase(replyReview.rejected, (state) => {});
  },
});

export const { setIsReplying, setTimeReq } = reviewsSlice.actions;

export const selectIsReplying = (state: RootState) => state.reviews.isReplying;

export const selectTimeReq = (state: RootState) => state.reviews.timeReq;
export const selectReplied = (state: RootState) => state.reviews.replied;
export const selectSkipForCircle = (state: RootState) =>
  state.reviews.skipForCircle;
export const selectNoReplied = (state: RootState) => state.reviews.noReplied;

export const selectReviewIsLoading = (state: RootState) =>
  state.reviews.isLoading;

const reviewsReducer = reviewsSlice.reducer;
export default reviewsReducer;
