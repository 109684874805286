import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Block } from "../../../../components/Block.styled";
import { Button } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { REACT_SHOP_REG_URL, httpStatusCodes } from "../../../../constant";
import { regShop } from "../../../../redux/slices/actionsMySlice";
import { setError } from "../../../../redux/slices/errorSlice";
import {
  selectMySliceIsLoading,
  selectUser,
} from "../../../../redux/slices/mySlice";

export const RegistrationForm = () => {
  const user = useSelector(selectUser);
  const isLoadingAPI = useSelector(selectMySliceIsLoading);
  const dispatch = useDispatch();

  const [shopDetails, setShopDetails] = useState({
    name: "",
    key: "",
  });

  const isFormValid = shopDetails.name !== "" && shopDetails.key !== "";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setShopDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      dispatch(
        setError({
          message: "Заполните все поля!",
          status: httpStatusCodes.BAD_REQUEST,
        })
      );
      return;
    }

    if (user) {
      await dispatch(
        regShop({
          url: REACT_SHOP_REG_URL,
          name: shopDetails.name,
          key: shopDetails.key,
          userIdTg: user.idTg,
        }) as any
      );
      setShopDetails({ name: "", key: "" });
    }
  };

  if (!user) {
    return <div>Ошибка загрузки данных</div>;
  }

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px" $justify="center">
        <StyledInputInline type="label">
          <label htmlFor="name">Название магазина:</label>
          <input
            type="text"
            value={shopDetails.name}
            id="name"
            placeholder="Любое слово"
            onChange={handleInputChange}
          />
        </StyledInputInline>
        <StyledInputInline>
          <label htmlFor="key">Ключ магазина:</label>
          <input
            type="text"
            value={shopDetails.key}
            id="key"
            placeholder="WB>Настройки>Доступ к API"
            onChange={handleInputChange}
          />
        </StyledInputInline>
        <Button
          $sizeButton="middle"
          type="button"
          disabled={!isFormValid || isLoadingAPI}
          onClick={handleSubmit}
        >
          Добавить магазин
        </Button>
      </FlexContainer>
    </Block>
  );
};
