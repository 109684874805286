import { Telegram } from "@twa-dev/types";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { App } from "./App";
import { GlobalStyles } from "./components/GlobalStyles";
import { myTheme } from "./components/Theme.styled";
import store from "./redux/store";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Modal.setAppElement("#root");
declare global {
  interface Window {
    Telegram: Telegram;
    YooMoneyCheckoutWidget: any;
    TelegramGameProxy: any;
  }
}
const InitTelegramGameProxy = () => {
  useEffect(() => {
    const initTelegramGameProxy = () => {
      if (!window.TelegramGameProxy) {
        window.TelegramGameProxy = { receiveEvent() {} };
      }
    };

    if (document.readyState === "complete") {
      initTelegramGameProxy();
    } else {
      window.addEventListener("load", initTelegramGameProxy);
      return () => window.removeEventListener("load", initTelegramGameProxy);
    }
  }, []);

  return null;
};

root.render(
  <ThemeProvider theme={myTheme}>
    <Provider store={store}>
      <InitTelegramGameProxy />
      <App />
      <GlobalStyles />
    </Provider>
  </ThemeProvider>
);
