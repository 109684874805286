import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { deleteItem, sendItem } from "./actionsAdminSlice";
import { buyItem, fetchItems } from "./actionsShopSlice";
import { ProductInterface } from "./interface/resp/Product.interface";

interface ShopState {
  items: Array<any>;
  urlByItemId: { [key: string]: string };
  isLoading: boolean;
}

const initialState: ShopState = {
  items: [],
  urlByItemId: {},
  isLoading: false,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setUrlForItem: (
      state,
      action: { payload: { itemId: string; url: string } }
    ) => {
      state.urlByItemId[action.payload.itemId] = action.payload.url;
    },
    clearUrlForItem: (state, action: { payload: { itemId: string } }) => {
      delete state.urlByItemId[action.payload.itemId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchItems.fulfilled,
        (state, action: { payload: Array<ProductInterface> }) => {
          if (action.payload) {
            state.items = action.payload;
            state.isLoading = false;
          }
        }
      )
      .addCase(fetchItems.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(sendItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        sendItem.fulfilled,
        (state, action: { payload: ProductInterface }) => {
          if (action.payload) {
            const item = state.items.find((s) => s.id === action.payload.id);
            if (item) {
              state.items = state.items.map((s) =>
                s.id === action.payload.id ? action.payload : s
              );
            } else {
              state.items = [...state.items, action.payload];
            }
            state.isLoading = false;
          }
        }
      )
      .addCase(sendItem.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deleteItem.fulfilled,
        (state, action: { payload: { id: string } }) => {
          if (action.payload.id) {
            state.items = state.items.filter((s) => s.id !== action.payload.id);

            state.isLoading = false;
          }
        }
      )
      .addCase(deleteItem.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(buyItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        buyItem.fulfilled,
        (
          state,
          action: {
            payload: {
              url: string;
              transactionId: string;
              itemId: string;
            };
          }
        ) => {
          if (action.payload.url && action.payload.itemId) {
            state.urlByItemId[action.payload.itemId] = action.payload.url;
            state.isLoading = false;
          }
        }
      )
      .addCase(buyItem.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setUrlForItem, clearUrlForItem } = shopSlice.actions;

export const selectItems = (state: RootState) => state.shop.items;
export const selectShopIsLoading = (state: RootState) => state.shop.isLoading;
export const selectUrlByItemId = (state: RootState, itemId: string) =>
  state.shop.urlByItemId[itemId];

const shopReducer = shopSlice.reducer;
export default shopReducer;
