import { useState } from "react";

import { useDispatch } from "react-redux";
import { Button } from "../../components/Button.styled";

import { StyledInputInline } from "../../components/Inline.styled";
import { TextAriaStyled } from "../../components/TextAria.styled";
import { REACT_CREATE_BIG_PROMO_URL } from "../../constant";
import { createBigPromocode } from "../../redux/slices/actionsAdminSlice";
import { SendBigPromoInterface } from "../../redux/slices/interface/req/SendBigPromoParams.interface";
import { UserInterface } from "../../redux/slices/interface/resp/User.interface";

export const GetCourseForm = ({ user }: { user: UserInterface }) => {
  const dispatch = useDispatch();

  const [nameProduct, setNameProduct] = useState<string>("");
  const [descriptionProduct, setDescriptionProduct] = useState<string>("");
  const [typeProduct, setTypeProduct] = useState<string>("");
  const [MyAnswersProduct, setMyAnswersProduct] = useState<number>(0);
  const [GPTProduct, setGPTProduct] = useState<number>(0);
  const [days, setDays] = useState<number>(0);
  const [promo, setPromo] = useState<string>("");
  const [mails, setMails] = useState<string>("");

  function sendNewBigPromo() {
    if (
      nameProduct === "" ||
      descriptionProduct === "" ||
      typeProduct === "" ||
      user === null ||
      promo === "" ||
      mails === ""
    ) {
      return;
    }
    const data: SendBigPromoInterface = {
      url: REACT_CREATE_BIG_PROMO_URL,
      userIdTg: user.idTg,
      name: nameProduct,
      description: descriptionProduct,
      type: typeProduct,
      MyAnswers: MyAnswersProduct,
      GPT: GPTProduct,
      id: "",
      days: days,
      promo: promo,
      mails: mails,
      price: 1,
    };

    dispatch(createBigPromocode(data) as any);
  }

  function checkerVisibleOfButton(): boolean {
    if (
      nameProduct === "" ||
      descriptionProduct === "" ||
      typeProduct === "" ||
      user === null ||
      promo === "" ||
      promo.length < 6 ||
      mails === ""
    ) {
      return false;
    }
    return true;
  }

  return (
    <>
      <StyledInputInline type="label">
        <label htmlFor={`name`}>
          <b>Название</b>
        </label>
        <input
          type="text"
          id={`name`}
          value={nameProduct}
          onChange={(e) => setNameProduct(e.target.value)}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`description`}>
          <b>Описание</b>
        </label>
        <TextAriaStyled
          style={{ boxSizing: "border-box" }}
          cacheMeasurements
          id={`description`}
          value={descriptionProduct}
          onChange={(e) => setDescriptionProduct(e.target.value)}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`type`}>
          <b>Тип</b>
        </label>
        <select
          id={`type`}
          value={typeProduct}
          onChange={(e) => setTypeProduct(e.target.value)}
        >
          <option value={""}>Не выбрано</option>
          <option value={"GPT"}>GPT</option>
          <option value={"MYANSWERS"}>MYANSWERS</option>
          <option value={"MYANSWERS_AND_GPT"}>MYANSWERS_AND_GPT</option>
          <option value={"SUBSCRIPTION"}>SUBSCRIPTION</option>
        </select>
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`MyAnswers`}>
          <b>MyAnswers</b>
        </label>
        <input
          type="number"
          min={0}
          id={`MyAnswers`}
          value={MyAnswersProduct}
          onChange={(e) => setMyAnswersProduct(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`GPT`}>
          <b>GPT</b>
        </label>
        <input
          type="number"
          min={0}
          id={`GPT`}
          value={GPTProduct}
          onChange={(e) => setGPTProduct(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`days`}>
          <b>Дни</b>
        </label>
        <input
          type="number"
          min={0}
          id={`days`}
          value={days}
          onChange={(e) => setDays(Number(e.target.value))}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </StyledInputInline>
      <StyledInputInline type="label">
        <label htmlFor={`promo`}>
          <b>Promo</b>
        </label>
        <input
          type="text"
          id={`promo`}
          value={promo}
          placeholder="Больше 6"
          onChange={(e) => setPromo(e.target.value.trim())}
        />
      </StyledInputInline>

      <TextAriaStyled
        style={{ boxSizing: "border-box" }}
        cacheMeasurements
        value={mails}
        placeholder="Введите почты svg форматом"
        onChange={(ev) => setMails(ev.target.value.trim())}
      />

      <Button
        onClick={() => sendNewBigPromo()}
        disabled={!checkerVisibleOfButton()}
      >
        Отправить
      </Button>
    </>
  );
};
