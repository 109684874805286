import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CreateBigPromoParamsInterface } from "./interface/req/CreateBigPromoParams.interface";
import { CreatePromoParamsInterface } from "./interface/req/CreatePromoParams.interface";
import { DeleteItemParamsInterface } from "./interface/req/DeleteItemParams.interface";
import { SendItemParamsInterface } from "./interface/req/SendItemParams.interface";
import { ResponseInterface } from "./interface/resp/ResponseBase.interface";
import { ErrorCheckerInSlice } from "./tools/ErrorCheckerInSlice";
import { ErrorSwitchInSlice } from "./tools/ErrorSwitchInSlice";

export const createPromocodes = createAsyncThunk(
  "promocodes/createPromocodes",
  async (
    {
      url,
      userIdTg,
      name,
      description,
      type,
      MyAnswers,
      GPT,
      id,
      days,
      quantity,
    }: CreatePromoParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        userIdTg,
        name,
        description,
        type,
        MyAnswers,
        GPT,
        id,
        days,
        quantity,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return !result.hasError;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const createBigPromocode = createAsyncThunk(
  "promocodes/createPromocodes",
  async (
    {
      url,
      userIdTg,
      name,
      description,
      type,
      MyAnswers,
      GPT,
      id,
      days,
      promo,
      mails,
    }: CreateBigPromoParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        userIdTg,
        name,
        description,
        type,
        MyAnswers,
        GPT,
        id,
        days,
        promo,
        mails,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return !result.hasError;
    } catch (error) {
      console.error(error);
      return ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);

export const sendItem = createAsyncThunk(
  "items/sendItems",
  async (
    {
      url,
      userIdTg,
      name,
      description,
      type,
      MyAnswers,
      GPT,
      id,
      price,
      days,
    }: SendItemParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        userIdTg: userIdTg.toString(),
        name,
        description,
        type,
        MyAnswers,
        GPT,
        id,
        price,
        days,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
export const deleteItem = createAsyncThunk(
  "items/deleteItems",
  async ({ url, userIdTg, id }: DeleteItemParamsInterface, thunkAPI) => {
    try {
      const res = await axios.post(url, {
        userIdTg: userIdTg.toString(),
        id,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
