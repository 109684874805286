export const myTheme = {
  colors: {
    dark: {
      primary: "#263659",
      secondary: "#100F17",
      back: `radial-gradient(circle, #263659, #100F17)`,
    },
    light: {
      primary: "#F4F4F4",
      secondary: "#F9CD59",
      thirdly: "##D9D9D9",
    },
  },
  fonts: {
    primary: "Gilroy Regular",
    secondary: "Gilroy Bold",
  },
  other: {
    borderRadius: "10px",
  },
};
