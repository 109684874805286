import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Block } from "../../../../components/Block.styled";
import { Button } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { PaymentForm } from "../../../../components/yk/yk";
import { httpStatusCodes, REACT_BUY_ITEM_URL } from "../../../../constant";
import { buyItem } from "../../../../redux/slices/actionsShopSlice";
import { setError } from "../../../../redux/slices/errorSlice";
import { ProductInterface } from "../../../../redux/slices/interface/resp/Product.interface";
import { UserInterface } from "../../../../redux/slices/interface/resp/User.interface";
import { selectUser } from "../../../../redux/slices/mySlice";
import {
  clearUrlForItem,
  selectUrlByItemId,
} from "../../../../redux/slices/shopSlice";

export const ItemBlock = ({ item }: { item: ProductInterface }) => {
  const dispatch = useDispatch();
  const user: UserInterface | null = useSelector(selectUser);
  const url = useSelector((state: any) => selectUrlByItemId(state, item.id));

  const [widgetShown, setWidgetShown] = useState(false);
  const [email, setEmail] = useState("");
  const [emailInputShown, setEmailInputShown] = useState(false);

  useEffect(() => {
    if (url && !widgetShown) {
      setWidgetShown(true);
    }
  }, [url, widgetShown]);

  useEffect(() => {
    return () => {
      dispatch(clearUrlForItem({ itemId: item.id }));
      setWidgetShown(false);
    };
  }, [item, dispatch]);

  function handleEmailSubmit() {
    if (!email || !validateEmail(email)) {
      dispatch(
        setError({
          message: "Заполните !",
          status: httpStatusCodes.BAD_REQUEST,
        })
      );
      return;
    }
    buyProduct(email);
    setEmailInputShown(false);
  }

  const buyProduct = (email: string) => {
    if (user === null) {
      return;
    }
    dispatch(
      buyItem({
        url: REACT_BUY_ITEM_URL,
        userIdTg: user.idTg,
        productId: item.id,
        email: email.toLowerCase(),
      }) as any
    );
  };

  const closePaymentForm = () => {
    dispatch(clearUrlForItem({ itemId: item.id }));
    setWidgetShown(false);
  };

  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <h2>
          <b>{item.name}</b>
        </h2>
        <p>{item.description}</p>
        {emailInputShown || widgetShown ? (
          <></>
        ) : (
          <Button
            disabled={emailInputShown || widgetShown}
            onClick={() => setEmailInputShown(true)}
          >
            Купить за {item.price}
          </Button>
        )}
        {emailInputShown && (
          <StyledInputInline type="button">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите ваш email"
              required
            />
            <Button onClick={handleEmailSubmit}>Подтвердить</Button>
          </StyledInputInline>
        )}
        {widgetShown && (
          <div id={item.id}>
            <PaymentForm
              confToken={url}
              reUrl=""
              onComplete={() => {
                dispatch(clearUrlForItem({ itemId: item.id }));

                setWidgetShown(false);
                alert("Payment accepted");
              }}
              itemId={item.id} // передаем уникальный идентификатор
            />
            <Button onClick={closePaymentForm}>Закрыть</Button>
          </div>
        )}
      </FlexContainer>
    </Block>
  );
};
