import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slices/adminSlice";
import errorReducer from "./slices/errorSlice";
import myReducer from "./slices/mySlice";
import reviewsReducer from "./slices/reviewsSlice";
import shopReducer from "./slices/shopSlice";

const store = configureStore({
  reducer: {
    my: myReducer,
    errorM: errorReducer,
    shop: shopReducer,
    reviews: reviewsReducer,
    admin: adminReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
