import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ErrorLitInterface {
  message: string | null;
  status: number | null;
}

interface ErrorStateInterface {
  error: ErrorLitInterface;
  isError: boolean;
}

const initialState: ErrorStateInterface = {
  error: { message: null, status: null },
  isError: false,
};

const errorSlice = createSlice({
  name: "errorM",
  initialState,
  reducers: {
    setError: (state, action: { payload: ErrorLitInterface }) => {
      state.error = action.payload;
      state.isError = true;
    },
    clearError: (state) => {
      state.error = { message: null, status: null };
      state.isError = false;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export const selectError = (state: RootState) => state.errorM.error;
export const selectIsError = (state: RootState) => state.errorM.isError;

export default errorSlice.reducer;
