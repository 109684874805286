import { createGlobalStyle } from "styled-components";
import { myTheme } from "./Theme.styled";

export const GlobalStyles = createGlobalStyle`
*,
*::before,
*:after {
  font-family: 'MyCustomFont';
  font-weight: 200;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


b, strong {
  font-weight: 700;
}

body {
  background: ${myTheme.colors.dark.back};
  color: ${myTheme.colors.light.primary};
  height: var(--tg-viewport-stable-height);
}
`;
