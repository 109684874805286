import styled from "styled-components";

type FlexContainerPropsType = {
  $direction?: "row" | "row-reverse" | "column" | "column-reverse";
  $justify?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  $align?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  $wrap?: "nowrap" | "wrap" | "wrap-reverse";
  $gap?: string;
  $width?: string;
};

export const FlexContainer = styled.div<FlexContainerPropsType>`
  display: flex;
  flex-direction: ${(props) => props.$direction ?? "row"};
  justify-content: ${(props) => props.$justify ?? "flex-start"};
  align-items: ${(props) => props.$align ?? "stretch"};
  flex-wrap: ${(props) => props.$wrap ?? "nowrap"};
  gap: ${(props) => props.$gap ?? "0px"};
  width: ${(props) => props.$width ?? "100%"};
`;
