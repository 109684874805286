import styled, { css } from "styled-components";
import { myTheme } from "./Theme.styled";
type StyledButtonType = {
  $fontSize?: string;
  $sizeButton?: "full" | "middle" | "little";
  $margin?: string;
  $bgc?: string;
};

export const Button = styled.button<StyledButtonType>`
  padding: 10px 10px;
  background-color: ${(props) => props.$bgc ?? myTheme.colors.light.secondary};
  color: ${myTheme.colors.dark.secondary};
  border: none;
  border-radius: ${myTheme.other.borderRadius};
  font-size: ${(props) => props.$fontSize ?? "1em"};
  text-align: center;
  ${(props) => props.$margin && `margin: ${props.$margin};`}
  transition: background-color 0.3s ease-in-out, color 0.3s ease;

  &:disabled {
    background-color: ${myTheme.colors.light.primary};
    color: ${myTheme.colors.dark.secondary};
    cursor: not-allowed;
  }

  ${(props) =>
    props["$sizeButton"] === "full" &&
    css<StyledButtonType>`
      width: 100%;
      max-width: 100%;
      font-size: 1.5em;
      margin-top: 0px;
    `}

  ${(props) =>
    props["$sizeButton"] === "middle" &&
    css<StyledButtonType>`
      max-width: 70%;
      font-size: 1em;
      align-self: flex-end;
    `}

  ${(props) =>
    props["$sizeButton"] === "little" &&
    css<StyledButtonType>`
      min-width: 30%;
      max-width: 50%;
      font-size: 1em;
      align-self: flex-end;
    `}
`;

type LittleButtonType = {
  $bgc?: string;
  color?: string;
};

export const LittleButton = styled.button<LittleButtonType>`
  background-color: ${(props) => props.$bgc ?? myTheme.colors.light.thirdly};
  color: ${(props) => props.color ?? myTheme.colors.dark.secondary};
  border: none;
  font-size: 1em;
  border-radius: 3px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
