import { useCallback, useEffect, useMemo, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Block } from "../../../../components/Block.styled";
import { Button, LittleButton } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { myTheme } from "../../../../components/Theme.styled";
import {
  REACT_SHOP_CHANGE_URL,
  REACT_SHOP_DELETE_URL,
  REACT_SHOP_UPDATE_URL,
} from "../../../../constant";
import {
  changeShop,
  changeShopMode,
  deleteShop,
} from "../../../../redux/slices/actionsMySlice";
import { ShopWithErrorInterface } from "../../../../redux/slices/interface/resp/Shop.interface";
import { selectShopIsLoading } from "../../../../redux/slices/shopSlice";
import sprite from "./../../../../assets/icons/sprite.svg";

const _ = require("lodash");

interface GPTStarsInterface {
  [key: string]: boolean;
}
const base: GPTStarsInterface = {
  "1": false,
  "2": false,
  "3": false,
  "4": false,
  "5": false,
};

export const ShopBlock = ({
  shop,
  userIdTg,
}: {
  shop: ShopWithErrorInterface;
  userIdTg: string;
}) => {
  const alreadyChosen = useMemo(() => {
    const initialChosen = { ...base };
    for (const star of shop.gptStars) {
      initialChosen[star] = true;
    }
    return initialChosen;
  }, [shop.gptStars]);

  const checkIsGptOn = useCallback(
    (starsToCheck: GPTStarsInterface): boolean => {
      return Object.values(starsToCheck).some((value) => value === true);
    },
    []
  );
  const initialStateForGPTSlider = useMemo(() => {
    return checkIsGptOn(alreadyChosen);
  }, [alreadyChosen, checkIsGptOn]);

  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(selectShopIsLoading);
  const [gptStars, setGptStars] = useState({ ...alreadyChosen });
  const [gptSlider, setGptSlider] = useState(initialStateForGPTSlider);
  const [timer, setTimer] = useState(shop.timer ?? 0);

  const [changeButton, setChangeButton] = useState(false);
  const [newKey, setNewKey] = useState("");

  function switchGPTSlider() {
    if (gptSlider) {
      setGptStars({ ...base });
      setTimer(0);
    } else if (initialStateForGPTSlider) {
      setGptStars(alreadyChosen);
      setTimer(shop.timer ?? 0);
    } else {
      const newStars = {
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
      };
      setGptStars(newStars);
      setTimer(1);
    }
    setGptSlider(!gptSlider);
  }

  function switchTimer(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setTimer(Number(e.target.value));
  }

  useEffect(() => {
    function switchChangeButton(
      alreadyChosen: GPTStarsInterface,
      gptStars: GPTStarsInterface,
      chosenTimer: number,
      timer: number
    ) {
      const hasChangeStars = !_.isEqual(alreadyChosen, gptStars);
      const hasChangeTimer = chosenTimer !== timer;
      const hasAnyStars = checkIsGptOn(gptStars);

      if (hasChangeStars || hasChangeTimer) {
        if (hasAnyStars && timer > 0) {
          setChangeButton(true);
          return;
        }
        if (!hasAnyStars && timer === 0) {
          setChangeButton(true);
          return;
        }
      }

      setChangeButton(false);
    }

    switchChangeButton(alreadyChosen, gptStars, shop.timer ?? 0, timer);

    setGptSlider(checkIsGptOn(gptStars));
  }, [
    gptStars,
    alreadyChosen,
    shop.timer,
    timer,
    setChangeButton,
    checkIsGptOn,
  ]);

  function sendShopKeyToServer() {
    const data = {
      url: REACT_SHOP_CHANGE_URL,
      name: shop.name,
      key: newKey,
      userIdTg,
      shopId: shop.id,
    };
    dispatch(changeShop(data) as any);
    setNewKey("");
  }

  function sendShopDataToServer() {
    const timerNullable = timer === 0 ? null : timer;
    const gptStarsArr = Object.keys(gptStars).filter(
      (star) => gptStars[star] === true
    );

    const data = {
      url: REACT_SHOP_UPDATE_URL,
      timer: timerNullable,
      gptStars: gptStarsArr,
      userIdTg,
      shopId: shop.id,
    };
    dispatch(changeShopMode(data) as any);
  }

  return (
    <Block>
      <FlexContainer $direction="column" $gap="10px">
        <FlexContainer $justify="space-between">
          <h2>
            <b> {shop.name}</b>{" "}
          </h2>
          <LittleButton
            onClick={() => {
              if (window.confirm("Вы уверены что хотите удалить магазин?")) {
                dispatch(
                  deleteShop({
                    url: REACT_SHOP_DELETE_URL,
                    userIdTg,
                    shopId: shop.id,
                  }) as any
                );
              }
            }}
          >
            <MdDeleteOutline />
          </LittleButton>
        </FlexContainer>
        {shop.error.hasError ? (
          <>
            <h2>Магазин недоступен. Причина:{shop.error.errorText}</h2>
            <StyledInputInline>
              <input
                type="text"
                value={newKey}
                onChange={(e) => setNewKey(e.target.value)}
                placeholder="Введите новый ключ от WB"
              />
            </StyledInputInline>
            <Button
              $sizeButton="full"
              disabled={newKey === "" || isLoading}
              onClick={sendShopKeyToServer}
            >
              Изменить
            </Button>
          </>
        ) : (
          <>
            <FlexContainer $justify="space-between" $align="center" $gap="4px">
              <StyledInputInline type="little" width="40%">
                <label htmlFor={`GPT-${shop.id}`}>GPT</label>
                <Switch
                  id={`GPT-${shop.id}`}
                  checked={gptSlider}
                  onChange={() => switchGPTSlider()}
                  handleDiameter={14}
                  height={16}
                  width={32}
                  onColor={`${myTheme.colors.light.secondary}`}
                  offColor={`${myTheme.colors.light.primary}`}
                  offHandleColor={`${myTheme.colors.light.secondary}`}
                />
              </StyledInputInline>
              <StyledInputInline type="little" width="80%">
                <label htmlFor={`timer-${shop.id}`}>Timer</label>
                <select
                  id={`timer-${shop.id}`}
                  value={timer}
                  onChange={(e) => switchTimer(e)}
                >
                  <option value={0}>Не выбрано</option>
                  <option value={1}>1</option>
                  <option value={3}>3</option>
                  <option value={5}>5</option>
                  <option value={12}>12</option>
                </select>
              </StyledInputInline>
            </FlexContainer>
            <FlexContainer $justify="space-between" $align="center" $gap="4px">
              {Object.keys(gptStars).map((star) => {
                const identifier = `rating-${shop.id}-${star}`;
                return (
                  <StyledInputInline type="little" width="19%" key={identifier}>
                    <label htmlFor={identifier}>
                      <b>{star}</b>
                    </label>
                    <svg
                      viewBox="0 0 32 32"
                      width="15px"
                      height="15px"
                      fill={
                        gptStars[star]
                          ? myTheme.colors.light.secondary
                          : myTheme.colors.light.primary
                      }
                    >
                      <use xlinkHref={`${sprite}#${"star"}`}></use>
                    </svg>
                    <input
                      type="checkbox"
                      id={identifier}
                      checked={gptStars[star]}
                      onChange={() =>
                        setGptStars({ ...gptStars, [star]: !gptStars[star] })
                      }
                    />
                  </StyledInputInline>
                );
              })}
            </FlexContainer>

            <Button
              $sizeButton="little"
              disabled={!changeButton || isLoading}
              onClick={sendShopDataToServer}
            >
              Изменить
            </Button>
          </>
        )}
      </FlexContainer>
    </Block>
  );
};
