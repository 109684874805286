import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { LoginButton } from "@telegram-auth/react";
import ErrorM from "./components/Error/ErrorM";
import { REACT_CHECK_USER_URL } from "./constant";
import { useTelegram } from "./hooks/useTelegram";
import { AdminPage } from "./layout/ADMIN/AdminPage";
import { MainMyPage } from "./layout/main/MyPage/MainMyPage";
import { MainReviewsPage } from "./layout/main/Reviews/MainReviewsPage";
import { MainProductsPage } from "./layout/main/ShopList/MainShopListPage";
import { MainLayout } from "./layout/MainLayout";
import { checkUserData } from "./redux/slices/actionsMySlice";

export function App() {
  const { tg, user } = useTelegram();
  const dispatch = useDispatch();
  const [initData, setInitData] = useState<string | object | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    tg.ready();
    tg.expand();
    if (user?.id?.toString() !== undefined) {
      setUserId(user?.id?.toString());
      setInitData(window.Telegram.WebApp.initData);
    }

    if (userId !== null && initData !== null) {
      dispatch(checkUserData({ url: REACT_CHECK_USER_URL, initData }) as any);
    }
  }, [dispatch, initData, tg, user?.id, userId]);

  if (!userId || !initData)
    return (
      <div className="App">
        <LoginButton
          botUsername={"preview_interview_bot"}
          onAuthCallback={(initData) => {
            console.log(initData);
            setUserId(initData.id.toString());
            setInitData(initData);
          }}
        />
      </div>
    );
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<MainMyPage />} />
            <Route path="buypage" element={<MainProductsPage />} />
            <Route path="reviewspage" element={<MainReviewsPage />} />
            <Route path="ad" element={<AdminPage />} />
          </Route>
        </Routes>
        <ErrorM />
      </div>
    </BrowserRouter>
  );
}
