import { BASE_ERR_MSG } from "../../../constant";
import { setError } from "../errorSlice";
import { ResponseInterface } from "../interface/resp/ResponseBase.interface";

export function ErrorCheckerInSlice(res: ResponseInterface, thunkAPI: any) {
  thunkAPI.dispatch(
    setError({
      message: res.errorText ?? BASE_ERR_MSG,
      status: res.status ?? 500,
    })
  );
  return thunkAPI.rejectWithValue(BASE_ERR_MSG);
}
