import { useCallback, useEffect, useMemo, useState } from "react";
import { IoReloadSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { DateTime } from "luxon";
import styled from "styled-components";
import { Button } from "../../../components/Button.styled";
import { FlexContainer } from "../../../components/FlexContainer";
import { myTheme } from "../../../components/Theme.styled";
import { REACT_REVIEWS_URL } from "../../../constant";
import { fetchReviews } from "../../../redux/slices/actionsReviewsSlice";
import { selectUser } from "../../../redux/slices/mySlice";
import {
  selectIsReplying,
  selectNoReplied,
  selectReplied,
  selectReviewIsLoading,
  selectSkipForCircle,
  selectTimeReq,
  setIsReplying,
  setTimeReq,
} from "../../../redux/slices/reviewsSlice";
import { ReviewBlock } from "./ReviewBlock/ReviewBlock";

export const MainReviewsPage = () => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const user = useSelector(selectUser);

  const isReplying = useSelector(selectIsReplying);
  const reviewIsLoading = useSelector(selectReviewIsLoading);
  const reviewsReplied = useSelector(selectReplied);
  const reviewsNoReplied = useSelector(selectNoReplied);
  const timeReq = useSelector(selectTimeReq);
  const skipForCircle = useSelector(selectSkipForCircle);

  const take = 30;
  const timeThreeMinutesAgo = DateTime.now()
    .setZone("Europe/Moscow")
    .minus({ minutes: 3 })
    .toJSDate()
    .getTime();

  const fetchUserReviews = useCallback(
    (isAnswered: boolean, take: number, skip?: number | null) => {
      if (user) {
        dispatch(
          fetchReviews({
            url: REACT_REVIEWS_URL,
            userIdTg: user.idTg,
            skip: skip ?? 0,
            take,
            isAnswered,
          }) as any
        );
      }
    },
    [dispatch, user]
  );

  useEffect(() => {
    if (timeReq === null || timeThreeMinutesAgo > timeReq) {
      fetchUserReviews(true, take);
      fetchUserReviews(false, take);
      dispatch(
        setTimeReq(
          DateTime.now()
            .setZone("Europe/Moscow")

            .toJSDate()
            .getTime()
        )
      );
    }
  }, [dispatch, fetchUserReviews, reload, timeReq, timeThreeMinutesAgo]);

  const reloadSwitch = useCallback(() => {
    dispatch(setTimeReq(null));
    setReload((prev) => !prev);
  }, [dispatch]);

  const currentReviews = useMemo(
    () => (isReplying ? reviewsReplied : reviewsNoReplied),
    [isReplying, reviewsReplied, reviewsNoReplied]
  );

  if (!user) {
    return null;
  }

  return (
    <FlexContainer $direction="column" $gap="25px">
      <FlexContainer $justify="space-between">
        <SpecialButton
          onClick={() => dispatch(setIsReplying(true))}
          disabled={isReplying}
        >
          Обработанные
        </SpecialButton>
        <SpecialButton
          onClick={() => dispatch(setIsReplying(false))}
          disabled={!isReplying}
        >
          Необработанные
        </SpecialButton>
        <Button
          $bgc={`${myTheme.colors.light.primary}`}
          onClick={reloadSwitch}
          disabled={reviewIsLoading}
        >
          <IoReloadSharp />
        </Button>
      </FlexContainer>
      {currentReviews.length === 0 ? (
        <>{reviewIsLoading ? `Загрузка` : "Кажется, отзывов нет"}</>
      ) : (
        <>
          {currentReviews.map((review) => (
            <ReviewBlock
              review={review}
              isAnswered={isReplying}
              key={review.id}
            />
          ))}
          {isReplying ? (
            <Button
              onClick={() => {
                fetchUserReviews(true, take, skipForCircle);
              }}
            >
              Показать еще
            </Button>
          ) : (
            <></>
          )}
        </>
      )}
    </FlexContainer>
  );
};

const SpecialButton = styled(Button)`
  background-color: transparent;
  color: ${myTheme.colors.light.primary};
  text-decoration: underline;
  &:disabled {
    background-color: ${myTheme.colors.light.primary};
    text-decoration: none;
  }
`;
