export const BASE_ERR_MSG = "Ошибка при загрузке данных";

export const httpStatusCodes = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const REACT_WEB_PROD: string = "https://smth.mybotwb.com";
export const REACT_SHOP_URL = `${REACT_WEB_PROD}/myshop`;
export const REACT_SHOP_REG_URL = `${REACT_WEB_PROD}/myshop/reg`;
export const REACT_SHOP_CHANGE_URL = `${REACT_WEB_PROD}/myshop/change`;
export const REACT_SHOP_UPDATE_URL = `${REACT_WEB_PROD}/myshop/update`;
export const REACT_SHOP_DELETE_URL = `${REACT_WEB_PROD}/myshop/delete`;

export const REACT_USER_URL = `${REACT_WEB_PROD}/myuser`;
export const REACT_CHECK_USER_URL = `${REACT_WEB_PROD}/auth/telegram_auth`;
export const REACT_ITEMS_URL = `${REACT_WEB_PROD}/myitems`;
export const REACT_REVIEWS_URL = `${REACT_WEB_PROD}/wb-data/get-reviews`;

export const REACT_PRODUCTS_URL = `${REACT_WEB_PROD}/product-item`;
export const REACT_CREATE_ITEM_URL = `${REACT_WEB_PROD}/product-item/fvd/s/reg`;
export const REACT_UPDATE_ITEM_URL = `${REACT_WEB_PROD}/product-item/fvd/s/update`;
export const REACT_DELETE_ITEM_URL = `${REACT_WEB_PROD}/product-item/fvd/s/delete`;
export const REACT_BUY_ITEM_URL = `${REACT_WEB_PROD}/product-item/buy`;

export const REACT_REVIEW_REPLY_URL = `${REACT_WEB_PROD}/wb-data/reply-to-review`;

export const REACT_CHANGE_EX_WORDS_URL = `${REACT_WEB_PROD}/exception-words`;

export const REACT_ACTIVE_PROMO_URL = `${REACT_WEB_PROD}/promo-item/active`;
export const REACT_CREATE_PROMO_URL = `${REACT_WEB_PROD}/promo-item/fvd/s/create`;

export const REACT_CREATE_BIG_PROMO_URL = `${REACT_WEB_PROD}/promo-item/fvd/s/create-big-promo`;
