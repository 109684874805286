import styled from "styled-components";

import TextareaAutosize from "react-textarea-autosize";
import { myTheme } from "./Theme.styled";
export const TextAriaStyled = styled(TextareaAutosize)`
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: ${myTheme.other.borderRadius};
  border: 1px solid ${myTheme.colors.light.secondary};
  background: ${myTheme.colors.dark.secondary};
  font-size: medium;
  color: ${myTheme.colors.light.primary};
`;
