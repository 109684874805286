import axios, { AxiosResponse } from "axios";
import { BASE_ERR_MSG } from "../../../constant";
import { setError } from "../errorSlice";

export function ErrorSwitchInSlice(error: any, thunkAPI: any) {
  console.error(error, "ErrorSwitchInSlice");
  if (axios.isAxiosError(error)) {
    const response = error.response as AxiosResponse;
    if (response.status === 400 && Array.isArray(response?.data?.message)) {
      const message = response.data.message
        .map(
          (item: { message: string; field: string }) =>
            `${item.field}: ${item.message}`
        )
        .join(", ");

      thunkAPI.dispatch(
        setError({
          message: message,
          status: 400,
        })
      );
    }
  }

  return thunkAPI.rejectWithValue(BASE_ERR_MSG);
}
