import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/Button.styled";
import { FlexContainer } from "../../../../components/FlexContainer";
import { StyledInputInline } from "../../../../components/Inline.styled";
import { httpStatusCodes, REACT_ACTIVE_PROMO_URL } from "../../../../constant";
import { activePromocode } from "../../../../redux/slices/actionsMySlice";
import { setError } from "../../../../redux/slices/errorSlice";
import { ActivePromocodeParamsInterface } from "../../../../redux/slices/interface/req/ActivePromoParams.interface";
import {
  clearSuccess,
  selectMySliceIsLoading,
  selectSuccess,
  selectUser,
} from "../../../../redux/slices/mySlice";

export const PromocodeBlock = () => {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const [promocode, setPromocode] = useState("");
  const [mail, setMail] = useState("");
  const isLoadingAPI = useSelector(selectMySliceIsLoading);
  const succeed: null | boolean = useSelector(selectSuccess);

  useEffect(() => {
    if (succeed !== null) {
      if (succeed) {
        dispatch(
          setError({
            message: "Промокод активирован!",
            status: httpStatusCodes.OK,
          })
        );
      } else {
        dispatch(
          setError({
            message: "Промокод не найден!",
            status: httpStatusCodes.NOT_FOUND,
          })
        );
      }
      dispatch(clearSuccess());
    }
  }, [succeed, dispatch]);

  if (user === undefined) {
    return <></>;
  }

  function checkPromocode() {
    if (promocode.length < 6) {
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    if (checkPromocode() && user !== null) {
      const data: ActivePromocodeParamsInterface = {
        url: REACT_ACTIVE_PROMO_URL,
        code: promocode,
        userIdTg: user.idTg,
      };
      if (mail !== "") {
        data.mail = mail;
      }

      dispatch(activePromocode(data) as any);
      setPromocode("");
    } else {
      dispatch(
        setError({
          message: "Заполните поле!",
          status: httpStatusCodes.BAD_REQUEST,
        })
      );
    }
  };

  return (
    <FlexContainer
      $gap="15px"
      $direction="column"
      $justify="center"
      $align="center"
    >
      <StyledInputInline>
        <input
          type="text"
          value={promocode}
          id="promocode"
          placeholder="ВВЕДИТЕ ПРОМОКОД"
          onChange={(event) => setPromocode(event.target.value.trim())}
        />
      </StyledInputInline>
      {promocode.length > 6 && (
        <StyledInputInline>
          <input
            type="text"
            value={mail}
            id="mail"
            placeholder="почта (не обязательно)"
            onChange={(event) => setMail(event.target.value.trim())}
          />
        </StyledInputInline>
      )}
      <Button
        $margin="10px 0px 30px 0px"
        $sizeButton="full"
        type="button"
        disabled={!checkPromocode() || isLoadingAPI}
        onClick={handleSubmit}
      >
        Активировать
      </Button>
    </FlexContainer>
  );
};
