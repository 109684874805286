import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BuyItemParamsInterface } from "./interface/req/BuyItemParams.interface.js";
import { ResponseInterface } from "./interface/resp/ResponseBase.interface";
import { ErrorCheckerInSlice } from "./tools/ErrorCheckerInSlice";
import { ErrorSwitchInSlice } from "./tools/ErrorSwitchInSlice";

export const fetchItems = createAsyncThunk(
  "items/fetchItems",
  async (url: string, thunkAPI) => {
    try {
      const res = await axios.get(url);
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return result.data;
    } catch (error) {
      console.error(error);
      ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);

export const buyItem = createAsyncThunk(
  "items/buyItem",
  async (
    { url, userIdTg, productId, email }: BuyItemParamsInterface,
    thunkAPI
  ) => {
    try {
      const res = await axios.post(url, {
        userIdTg: userIdTg.toString(),
        productId,
        email,
      });
      const result: ResponseInterface = res.data;
      if (result.hasError) {
        return ErrorCheckerInSlice(result, thunkAPI);
      }
      return { ...result.data, itemId: productId };
    } catch (error) {
      console.error(error);
      ErrorSwitchInSlice(error, thunkAPI);
    }
  }
);
