import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const PaymentFormContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin: 10px auto;
`;

interface PaymentFormProps {
  confToken: string;
  reUrl: string;
  onComplete: () => void;
  itemId: string;
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  confToken,
  reUrl,
  onComplete,
  itemId,
}) => {
  const paymentFormRef = useRef(true);
  const [isLoading, setIsLoading] = useState(true);

  const initializeWidget = useCallback(() => {
    setIsLoading(false);
    const checkout = new (window as any).YooMoneyCheckoutWidget({
      confirmation_token: confToken,
      return_url: reUrl,
      error_callback: (error: any) => {
        console.log(error);
      },
    });
    checkout.on("complete", () => {
      onComplete();
      checkout.destroy();
    });

    checkout.render(`payment-form-${itemId}`).then(() => {
      // Кода после отображения платежной формы
    });
  }, [confToken, reUrl, onComplete, itemId]);

  useEffect(() => {
    if (paymentFormRef.current) {
      const script = document.createElement("script");
      script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
      script.async = true;
      script.onload = initializeWidget;
      document.head.appendChild(script);
      paymentFormRef.current = false;
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [initializeWidget]);

  return (
    <PaymentFormContainer>
      <div id={`payment-form-${itemId}`} />
    </PaymentFormContainer>
  );
};
