import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  clearError,
  ErrorLitInterface,
  selectError,
  selectIsError,
} from "../../redux/slices/errorSlice";

const ErrorM = () => {
  const isError: boolean = useSelector(selectIsError);
  const errorObj: ErrorLitInterface = useSelector(selectError);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isError) {
      toast.error(errorObj.message);
      dispatch(clearError());
    }
  }, [isError, errorObj, dispatch]);
  return <ToastContainer position="top-right" autoClose={2000} />;
};

export default ErrorM;
