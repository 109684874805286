import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { createPromocodes } from "./actionsAdminSlice";

interface AdminStateInterface {
  isLoading: boolean;
  codes: boolean;
}

const initialState: AdminStateInterface = {
  isLoading: false,
  codes: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPromocodes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        createPromocodes.fulfilled,
        (state, action: { payload: boolean }) => {
          state.isLoading = false;
          state.codes = action.payload;
        }
      )
      .addCase(createPromocodes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const selectAdminIsLoading = (state: RootState) => state.admin.isLoading;
export const selectAdminCodes = (state: RootState) => state.admin.codes;

const adminReducer = adminSlice.reducer;
export default adminReducer;
