import styled from "styled-components";
import { myTheme } from "./Theme.styled";

export const Block = styled.section`
  display: flex;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  background: ${myTheme.colors.dark.primary};
  border: 2px solid ${myTheme.colors.light.secondary};
`;
